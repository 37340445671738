import { Fragment, useContext, useState } from 'react';
import { format, isMatch } from "date-fns";
import Client from "../../models/serviceModels/Client";
import { formatJSONDateWithoutTime, parseJSONDateWithoutTime } from "../../utils/dateHelpers";
import Button from './Button';
import Modal from './Modal';
import ErrorMessages from './ErrorMessages';
import Input from './Input';
import { activateClient, deactivateClient, deleteClient } from '../../services/clientsService';
import Protect from '../Layout/Protect';
import TruncatedText from './TruncatedText';
import ParticipantFiles from './ParticipantFiles';
import { MeContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { deleteParticipantsEnabled } from '../../utils/features';

interface ParticipantProfileHeaderProps {
    client: Client,
    hideEdit?: boolean;
    onUpdated: () => void;
}

const defaultDate = format(new Date(), 'yyyy-MM-dd');

const ParticipantProfileHeader = ({ client, hideEdit = false, onUpdated }: ParticipantProfileHeaderProps) => {
    const [showHousehold, setShowHousehold] = useState(false);
    const [showActivation, setShowActivation] = useState(false);
    const [activatedDate, setActivatedDate] = useState(defaultDate);
    const [deactivatedDate, setDeactivatedDate] = useState(defaultDate);
    const [showDeactivation, setShowDeactivation] = useState(false);
    const [modalErrors, setModalErrors] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const me = useContext(MeContext);
    const navigate = useNavigate();

    const handleShowActivation = () => {
        setModalErrors([]);
        setShowActivation(true);
        setSubmitting(false);
    };

    const handleShowDeactivation = () => {
        setModalErrors([]);
        setShowDeactivation(true);
        setDeactivatedDate(defaultDate);
        setSubmitting(false);
    };

    const handleActivation = () => {
        if (!activatedDate) {
            setModalErrors(['Activate Date is required']);
            return;
        }

        if (!isMatch(activatedDate, 'yyyy-MM-dd')) {
            setModalErrors(['Activate Date is invalid']);
            return;
        }

        setSubmitting(true);

        activateClient({
            clientId: client.id,
            date: activatedDate
        }).then(() => {
            setShowActivation(false);
            if (onUpdated) {
                onUpdated();
            }
        }).catch(e => {
            console.error(e);
            setModalErrors(['Failed to activate client']);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const handleDeactivation = () => {
        if (!deactivatedDate) {
            setModalErrors(['Deactivate Date is required']);
            return;
        }

        if (!isMatch(deactivatedDate, 'yyyy-MM-dd')) {
            setModalErrors(['Deactivate Date is invalid']);
            return;
        }

        setSubmitting(true);

        deactivateClient({
            clientId: client.id,
            date: deactivatedDate
        }).then(() => {
            setShowDeactivation(false);
            if (onUpdated) {
                onUpdated();
            }
        }).catch(e => {
            console.error(e);
            setModalErrors(['Failed to deactivate client']);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const handleDeletion = () => {
        setSubmitting(true);
        setModalErrors([]);

        deleteClient(client.id).then(() => {
            navigate('/', { 
                replace: true
            });
        }).catch(e => {
            console.error(e);
            setModalErrors(['Failed to delete participant']);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const handleShowConfirmDeletion = () => {
        setModalErrors([]);
        setConfirmDeletion(true);
        setSubmitting(false);
    };

    return (
        <>
            <div className="row">
                <div className="column-large-7">
                    <div className="row align-items-center justify-content-between">
                        <div className="column">
                            <div className="row align-items-center">
                                <div className="column-auto">
                                    <h2>{client.firstName} {client.middleName} {client.lastName}
                                        {client.pronouns && <div className="text-sub">{client.pronouns}</div>}
                                    </h2>
                                </div>
                                <div className="column-auto">
                                    <small>
                                        <div>Created - {formatJSONDateWithoutTime(client.created, 'MM/dd/yyyy')}</div>
                                        {(client.updated && <div>Updated - {formatJSONDateWithoutTime(client.updated, 'MM/dd/yyyy')}</div>) || null}
                                        {(client.activated && !client.deactivated && <div> Activated - {formatJSONDateWithoutTime(client.activated, 'MM/dd/yyyy')}</div>) || null}
                                        {(client.deactivated && <div> Deactivated - {formatJSONDateWithoutTime(client.deactivated, 'MM/dd/yyyy')}</div>) || null}
                                    </small>
                                </div>
                                <Protect user={me.user} behavior="hide" roles={['Admin', 'Contributor']}><div className="column-auto">
                                    {client.activated && !client.deactivated && <Button id="deactivate-participant" className="small secondary" onClick={handleShowDeactivation} text="Deactivate" />}
                                    {(!client.activated || client.deactivated) && <Button id="activate-participant" className="small" onClick={handleShowActivation} text="Activate" />}
                                </div></Protect>
                            </div>
                        </div>
                        <Protect user={me.user} behavior="hide" roles={['Admin', 'Contributor']}><> {!hideEdit && <div className="column-auto">
                            <Button id="edit-profile-button"
                                text="Edit"
                                className="small"
                                href={`/participants/${client.id}/edit`} />
                        </div>}</></Protect>
                        {deleteParticipantsEnabled && <Protect user={me.user} behavior="hide" roles={['Admin', 'Contributor']}>
                            <div className="column-auto">
                                <Button id="delete-profile-button" 
                                    text="Delete"
                                    className="small"
                                    onClick={handleShowConfirmDeletion} />
                            </div>
                        </Protect>}
                    </div>
                    <div className="row margin-vertical-5">
                        <div className="column-medium">
                            <div className="row margin-top-2">
                                <div className="column-auto">
                                    <label>Birth Date:</label> {client.dateOfBirth && formatJSONDateWithoutTime(client.dateOfBirth, 'MM/dd/yyyy')}
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column-auto">
                                    <label>Phone:</label> {client.phoneNumber || ''}
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column-auto">
                                    <label>Email:</label> {client.email || ''}
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column-auto">
                                    <label>Gender:</label> {client.genderName}
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column-auto wrap-text inline-size-6">
                                    <label>Race(s):</label>&nbsp;
                                    <TruncatedText
                                        id="races"
                                        value={client.races.map(r => r.name).join(', ')}
                                        maxLength={30}
                                        lengthVariance={10} />
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column-auto">
                                    <label>Ethnicity:</label> {client.ethnicityName}
                                </div>
                            </div>
                        </div>
                        <div className="column-medium">
                            <div className="row margin-top-2">
                                <div className="column-auto">
                                    <label>Address:</label>
                                    <div>{client.addressLine1}</div>
                                    {client.addressLine2 && (<div>{client.addressLine2}</div>)}
                                    <div>{client.addressCity}, {client.addressState} {client.addressZipCode}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column-large-5">
                    <ParticipantFiles clientId={client.id} />
                </div>
            </div>
            <div className="row margin-top-2">
                <div className="column-auto">
                    <Button id="show-household" text={showHousehold ? 'Hide Household' : 'Show Household'}
                        icon={showHousehold ? (<i className="ri-arrow-up-s-line" />) : (<i className="ri-arrow-down-s-line" />)}
                        iconLocation="end"
                        className="secondary"
                        onClick={() => setShowHousehold(!showHousehold)} />
                </div>
            </div>
            {showHousehold && (<div className="row">
                <div className="column">
                    <div className="row">
                        <div className="column-auto">
                            <h2>Household Members</h2>
                        </div>
                    </div>
                    {(!client.householdMembers || client.householdMembers.length === 0) && <div>None</div>}
                    {client.householdMembers && client.householdMembers.length > 0 && <div className="list-item-container">
                        {client.householdMembers.map(hhm => {
                            return (<Fragment key={hhm.id}>
                                <div className="list-item" key={hhm.id}>
                                    <div className="row align-items-top">
                                        <div className="column-medium-3">
                                            <label>Name:</label>
                                            <div>{hhm.firstName} {hhm.middleName} {hhm.lastName}</div>
                                        </div>
                                        <div className="column-medium">
                                            <div className="row align-items-top">
                                                <div className="column-medium-3">
                                                    <label>Relationship:</label>
                                                    <div>{hhm.relationshipName}</div>
                                                </div>
                                                <div className="column-medium-3">
                                                    <label>Birth Date:</label>
                                                    <div>{hhm.dateOfBirth && format(parseJSONDateWithoutTime(hhm.dateOfBirth), 'MM/dd/yyyy')}</div>
                                                </div>
                                                <div className="column-medium-3">
                                                    <label>Gender:</label>
                                                    <div>{hhm.genderName}</div>
                                                </div>
                                                <div className="column-medium-3">
                                                    <label>Races:</label>
                                                    <div><TruncatedText
                                                        id="races"
                                                        value={hhm.races.map(r => r.name).join(', ')}
                                                        maxLength={15} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(hhm.email || hhm.phoneNumber) && <div className="row align-items-center">
                                        {hhm.email && <div className="column-medium-3">
                                            <label>Email:</label>
                                            <div>{hhm.email}</div>
                                        </div>}
                                        {hhm.phoneNumber && <div className="column-medium-3">
                                            <label>Phone Number:</label>
                                            <div>{hhm.phoneNumber}</div>
                                        </div>}
                                    </div>}
                                </div>
                            </Fragment>);
                        })}
                    </div>}
                </div>
            </div>)}
            <Modal id="delete-modal" visible={confirmDeletion} className="small" title="Confirm Delete Participant" onClose={() => setConfirmDeletion(false)}>
                <>
                    {modalErrors && <ErrorMessages messages={modalErrors} />}
                    <div className="row">
                        <div className="column">
                            <div>Are you sure you want to delete this participant?</div>
                            <div>This action is irreversible.</div>
                            <div>All created data associated with this participant will also be deleted.</div>
                            <ul>
                                <li>Case Plans</li>
                                <li>Files</li>
                                <li>Notes</li>
                                <li>Program Assignments</li>
                                <li>Report Results</li>
                                <li>Submitted Forms</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-auto">
                                    <Button id="cancel-delete"
                                        text="Cancel" 
                                        className="secondary" 
                                        disabled={submitting} 
                                        onClick={() => setConfirmDeletion(false)} />
                                </div>
                                <div className="column-auto">
                                    <Button id="execute-delete"
                                        text="Delete"
                                        className="warning"
                                        disabled={submitting}
                                        loading={submitting}
                                        onClick={handleDeletion} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
            <Modal id="activate-modal" visible={showActivation} className="small" title="Activate Participant" onClose={() => setShowActivation(false)}>
                <>
                    {modalErrors && <ErrorMessages messages={modalErrors} />}
                    <div className="row">
                        <div className="column">
                            <Input id="active-date" type="date" label="Provide an Active Date" value={activatedDate} onChange={(e) => setActivatedDate(e)} />
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-activate"
                                        text="Cancel" 
                                        className="secondary" 
                                        disabled={submitting} 
                                        onClick={() => setShowActivation(false)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-activate" 
                                        text="Activate" 
                                        disabled={submitting} 
                                        loading={submitting} 
                                        onClick={handleActivation} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
            <Modal id="deactivate-modal" visible={showDeactivation} className="small" title="Confirm Deactivate Participant" onClose={() => setShowDeactivation(false)}>
                <>
                    {modalErrors && <ErrorMessages messages={modalErrors} />}
                    <div className="row">
                        <div className="column">
                            <Input id="deactive-date" type="date"
                                label="Provide a Deactivate Date"
                                required={true}
                                value={deactivatedDate}
                                onChange={(e) => setDeactivatedDate(e)} />
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-deactivate" 
                                        text="Cancel" 
                                        className="secondary" 
                                        disabled={submitting} 
                                        onClick={() => setShowDeactivation(false)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-deactivate" 
                                        text="Deactivate" 
                                        disabled={submitting} 
                                        loading={submitting} 
                                        onClick={handleDeactivation} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default ParticipantProfileHeader;