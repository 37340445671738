import { useEffect, useState } from "react";
import { getForm, getFormInstance } from "../../services/formsService";
import FormInstance from "../../models/serviceModels/FormInstance";
import Form from "../../models/serviceModels/Form";
import EditableFormField from "../../models/pageModels/EditableFormField";
import { mapFormFieldToEditableFormField } from "../../mappers/formFieldMapper";
import Loader from "../UI/Loader";
import ApiErrorMessages from "../UI/ApiErrorMessages";
import { formatJSONDateWithoutTime } from "../../utils/dateHelpers";
import FormEditor from "./Editor/FormEditor";

interface PreviewFormInstanceProps {
    formId: string;
    versionId: string;
    instanceId: string;
}

const PreviewFormInstance = (props: PreviewFormInstanceProps) => {
    const [loading, setLoading] = useState(false);
    const [instance, setInstance] = useState<FormInstance>();
    const [form, setForm] = useState<Form>();
    const [fields, setFields] = useState<EditableFormField[]>([]);
    const [apiErrors, setApiErrors] = useState<any>();

    useEffect(() => {
        setLoading(true);

        Promise.all([getForm(props.formId, props.versionId), getFormInstance(props.formId, props.instanceId)]).then(([f, fi]) => {
            setInstance(fi);
            setForm(f);
            setFields([
                ...f.fields.map(ff => {
                    const instanceField = fi.instanceFields.find(iff => iff.name === ff.name);
                    return mapFormFieldToEditableFormField(ff, instanceField?.value || '', instanceField?.id || '', instanceField?.files || []);
                })
            ]);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loader />;
    }

    return <>
        <ApiErrorMessages error={apiErrors} />
        {form && instance && <>
            <div className="row align-items-center">
                <div className="column-auto">
                    <h1>{form?.name}</h1>
                </div>
                {instance && <div className="column-auto">
                    <small>
                        <div>Originally Submitted - {formatJSONDateWithoutTime(instance.created, 'MM/dd/yyyy')}</div>
                        {(instance.updated && <div>Last Updated - {formatJSONDateWithoutTime(instance.updated, 'MM/dd/yyyy')}</div>) || null}
                    </small>
                </div>}
            </div>
            <div className="row">
                <div className="column">
                    <FormEditor fields={fields} disabled={true} />
                </div>
            </div>
        </>}
    </>;
}

export default PreviewFormInstance;