import { useEffect, useState } from "react";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Checkbox from "../components/UI/Checkbox";
import Loader from "../components/UI/Loader";
import { deleteProgram, searchPrograms } from "../services/programsService";
import { deleteProgramsEnabled } from "../utils/features";
import Dropdown from "../components/UI/Dropdown";
import Program from "../models/serviceModels/Program";
import Modal from "../components/UI/Modal";
import ErrorMessages from "../components/UI/ErrorMessages";

const TAKE = 1000;

const ManagePrograms = () => {
    const [loading, setLoading] = useState(true);
    const [programs, setPrograms] = useState<Program[]>();
    const [includeDeactivated, setIncludeDeactivated] = useState<boolean>(false);
    const [apiError, setApiError] = useState<any>();
    const [confirmDeleteProgram, setConfirmDeleteProgram] = useState<Program>();
    const [modalError, setModalError] = useState<any>();
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        document.title = "Manage Programs"
    }, []);

    useEffect(() => {
        setLoading(true);
        searchPrograms({ query: '', skip: 0, take: TAKE, includeDeactivated }).then(({ data }) => {
            setPrograms(data);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, [includeDeactivated]);

    const handleOnConfirmDelete = (program: Program) => {
        setModalError(undefined);
        setConfirmDeleteProgram(program);
        setDeleting(false);
    };

    const handleOnDelete = async () => {
        if (!confirmDeleteProgram) {
            console.warn('Unable to delete, no program selected');
            return;
        }

        setDeleting(true);

        try {
            await deleteProgram(confirmDeleteProgram.id);
            const { data } = await searchPrograms({ query: '', skip: 0, take: TAKE, includeDeactivated });
            setPrograms(data);
            setConfirmDeleteProgram(undefined);
        } catch (e) {
            console.error(e);
            setModalError(e);
        } finally {
            setDeleting(false);
        }
    }

    const getResults = () => {
        return (<><div className="row margin-vertical-2 justify-content-center">
            <div className="column-medium">
                <table className="table">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {programs && programs.map((p: any) => {
                            const options: { label: string; href?: string; onClick?: () => void }[] = [
                                { label: 'Manage Forms', href: `/admin/programs/${p.id}/forms` },
                                { label: 'Edit', href: `/admin/programs/${p.id}/edit` }
                            ];

                            if (deleteProgramsEnabled) {
                                options.push({
                                    label: 'Delete',
                                    onClick: () => handleOnConfirmDelete(p),
                                });
                            }
                            return (<tr key={p.id} className="table-row">
                                <td>{p.name}</td>
                                <td>{!p.deactivated ? 'Yes' : <span className="text-danger">No</span>}</td>
                                <td>
                                    <div className="row justify-content-end">
                                        <div className="column-auto">
                                            <Dropdown id="program-options"
                                                options={options} 
                                                optionSize="large" />
                                        </div>
                                    </div>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
            <Modal id="delete-modal" visible={confirmDeleteProgram !== undefined} className="small" onClose={() => setConfirmDeleteProgram(undefined)} title="Confirm Delete of Program">
                {confirmDeleteProgram && (
                    <div>
                        {modalError && <ErrorMessages messages={[modalError]} />}
                        <div className="row">
                            <div className="column">
                                <div className="text-bold">{confirmDeleteProgram.name}</div>
                                <br />
                                <div>Deleting this program means the following:</div>
                                <ul>
                                    <li>Program enrollment history data is <span className="text-bold">deleted</span> (e.g. no record that J.J. Jones was ever enrolled)</li>
                                    <li>All tracking forms for this program will be <span className="text-bold">unassigned</span>.</li>
                                    <li>Those unassigned forms will be <span className="text-bold">kept</span> to use again.</li>
                                    <li>All data submitted through these tracking forms will be <span className="text-bold">kept</span> for reporting.</li>
                                </ul>
                                <br />
                                <div>Are you sure you want to delete this program?</div>
                                <div className="text-bold">The change is irreversible.</div>
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-delete"
                                            text="Cancel"
                                            className="secondary"
                                            disabled={deleting}
                                            onClick={() => setConfirmDeleteProgram(undefined)} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-delete"
                                            text="Delete Program"
                                            className="warning"
                                            disabled={deleting}
                                            loading={deleting}
                                            onClick={handleOnDelete} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </>);
    };

    const getMainContent = () => {
        return (<section id="main-content" tabIndex={-1}>
            {loading && <Loader />}
            {!loading && (
                <>
                    <h1>Manage Programs</h1>
                    <div className="row justify-content-center margin-vertical-2">
                        <div className="column-medium">
                            <ApiErrorMessages error={apiError} />
                            <div className="row justify-content-between margin-vertical-4">
                                <div className="column-auto">
                                    <Checkbox id="include-deactivated" checked={includeDeactivated} containerClassName="margin-top-0 unboxed" label="Show Deactivated" onChange={setIncludeDeactivated} />
                                </div>
                                <div className="column-auto">
                                    <Button id="create-user-button" text="Create Program" href="/admin/programs/create" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {programs && programs.length > 0 && getResults()}
                    {programs && programs.length === 0 && <div className="row justify-content-center margin-vertical-2">
                        <div className="column-auto">
                            <p>No Programs Found</p>
                        </div>
                    </div>}
                </>
            )}
        </section>)
    };

    return getMainContent();
};

export default ManagePrograms;