import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Protect from '../components/Layout/Protect';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import BackButton from "../components/UI/BackButton"
import Button from '../components/UI/Button';
import Loader from '../components/UI/Loader';
import Form from '../models/serviceModels/Form';
import FormInstance from '../models/serviceModels/FormInstance';
import { deleteFormInstance, getForm, getFormInstance } from '../services/formsService';
import { formatJSONDateWithoutTime } from '../utils/dateHelpers';
import FormEditor from '../components/Form/Editor/FormEditor';
import EditableFormField from '../models/pageModels/EditableFormField';
import { mapFormFieldToEditableFormField } from '../mappers/formFieldMapper';
import { MeContext } from '../App';
import Modal from '../components/UI/Modal';
import ErrorMessages from '../components/UI/ErrorMessages';
import { deleteFormInstancesEnabled } from '../utils/features';

const ViewFormInstance = () => {
    const { formId, instanceId, versionId } = useParams();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<Form>();
    const [instance, setInstance] = useState<FormInstance>();
    const [apiErrors, setApiErrors] = useState<any>();
    const [fields, setFields] = useState<EditableFormField[]>([]);
    const [modalError, setModalError] = useState<any>();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const me = useContext(MeContext);

    useEffect(() => {
        document.title = "View Submitted Form Data"
    }, []);

    useEffect(() => {
        setLoading(true);

        if (!formId || !instanceId || !versionId) {
            return;
        }

        Promise.all([getForm(formId, versionId), getFormInstance(formId, instanceId)]).then(([f, fi]) => {
            setInstance(fi);
            setForm(f);
            setFields([
                ...f.fields.map(ff => {
                    const instanceField = fi.instanceFields.find(iff => iff.name === ff.name);
                    return mapFormFieldToEditableFormField(ff, instanceField?.value || '', instanceField?.id || '', instanceField?.files || []);
                })
            ]);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleOnConfirmDelete = () => {
        setModalError(undefined);
        setConfirmDelete(true);
        setDeleting(false);
    };

    const handleOnDelete = async () => {
        if (!formId || !instanceId) {
            console.warn('No formId or instanceId set');
            return;
        }

        setDeleting(true);
        try {
            await deleteFormInstance(formId, instanceId);
            if (location.key === 'default') {
                navigate('/', {
                    replace: true
                });
            } else {
                navigate(-1);
            }
        } catch (e) {
            console.error(e);
            setModalError(e);
        } finally {
            setDeleting(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (<section id="main-content" tabIndex={-1}>
        <div className="section">
            <div className="row justify-content-center">
                <div className="column-medium-6">
                    <div className="row justify-content-between align-items-center">
                        <div className="column-auto">
                            <BackButton />
                        </div>
                        <Protect user={me.user} roles={['Admin', 'Contributor']} behavior="hide">
                            <div className="column-auto align-items-center">
                                <div className="row">
                                    {deleteFormInstancesEnabled && <div className="column-auto">
                                        <Button id={`delete-${instanceId}`}
                                            className="small"
                                            onClick={handleOnConfirmDelete}
                                            text="Delete" />
                                    </div>}
                                    <div className="column-auto">
                                        <Button id={`edit-${instanceId}`}
                                            className="small" href={`/forms/${formId}/versions/${versionId}/instances/${instanceId}/edit`}
                                            text="Edit" />
                                    </div>
                                </div>
                            </div>
                        </Protect>
                    </div>
                    <ApiErrorMessages error={apiErrors} />
                    {form && instance && <>
                        <div className="row align-items-center">
                            <div className="column-auto">
                                <h1>{form?.name}</h1>
                            </div>
                            {instance && <div className="column-auto">
                                <small>
                                    <div>Originally Submitted - {formatJSONDateWithoutTime(instance.created, 'MM/dd/yyyy')}</div>
                                    {(instance.updated && <div>Last Updated - {formatJSONDateWithoutTime(instance.updated, 'MM/dd/yyyy')}</div>) || null}
                                </small>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="column">
                                <FormEditor fields={fields} disabled={true} />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
        <Modal id="delete-modal" visible={confirmDelete} className="small" onClose={() => setConfirmDelete(false)} title="Confirm Delete of Form Submission">
            <div>
                {modalError && <ErrorMessages messages={[modalError]} />}
                <div className="row">
                    <div className="column">
                        <div>Are you sure you want to delete this Form Submission?</div>
                        <br />
                        <div className="text-bold">This action is irreversible.</div>
                    </div>
                </div>
                <div className="row margin-top-5">
                    <div className="column">
                        <div className="row justify-content-between">
                            <div className="column-medium-auto">
                                <Button id="cancel-delete"
                                    text="Cancel"
                                    className="secondary"
                                    disabled={deleting}
                                    onClick={() => setConfirmDelete(false)} />
                            </div>
                            <div className="column-medium-auto">
                                <Button id="confirm-delete"
                                    text="Delete Submission"
                                    className="warning"
                                    disabled={deleting}
                                    loading={deleting}
                                    onClick={handleOnDelete} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </section>
    );
};

export default ViewFormInstance;