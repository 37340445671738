const params = new URLSearchParams(window.location.search);
const keysToRemove = []
for(const key of Array.from(params.keys())) {
    if (key.indexOf('FEATURE_') === 0){
        const value = params.get(key);
        if (value) {
            localStorage.setItem(key, value);
            keysToRemove.push(key);
        }
    }
}

if (keysToRemove.length !== 0) {
    for(const key of keysToRemove) {
        params.delete(key);
    }

    if (Array.from(params.keys()).length === 0) {
        window.location.search = '';
    } else {
        window.location.search = `?${params}`;
    }
}

const overridenFeatures: { [key:string]: string } = {}
for(let i = 0; i < localStorage.length; i++) {
    const localStoragekey = localStorage.key(i);
    if (localStoragekey?.indexOf('FEATURE_') === 0) {
        const value = localStorage.getItem(localStoragekey);
        if (value) {
            overridenFeatures[localStoragekey] = value;
        }
    }
}

const deleteProgramsEnabled = process.env.REACT_APP_FEATURE_ENABLE_DELETE_PROGRAMS === 'true' || overridenFeatures['FEATURE_ENABLE_DELETE_PROGRAMS'] === 'true';
const deleteFormsEnabled = process.env.REACT_APP_FEATURE_ENABLE_DELETE_FORMS === 'true' || overridenFeatures['FEATURE_ENABLE_DELETE_FORMS'] === 'true';
const deleteFormInstancesEnabled = process.env.REACT_APP_FEATURE_ENABLE_DELETE_FORM_INSTANCES === 'true' || overridenFeatures['FEATURE_ENABLE_DELETE_FORM_INSTANCES'] === 'true';
const deleteParticipantsEnabled = process.env.REACT_APP_FEATURE_ENABLE_DELETE_PARTICIPANTS === 'true' || overridenFeatures['FEATURE_ENABLE_DELETE_PARTICIPANTS'] === 'true';
const dataVisualizationsEnabled = process.env.REACT_APP_FEATURE_ENABLE_DATA_VISUALIZATIONS === 'true' || overridenFeatures['FEATURE_ENABLE_DATA_VISUALIZATIONS'] === 'true';

export {
    dataVisualizationsEnabled,
    deleteParticipantsEnabled,
    deleteFormInstancesEnabled,
    deleteFormsEnabled,
    deleteProgramsEnabled
}